import { useEffect } from "react";
import videoBg from '../assets/homepage-vid-2.mp4'
import AutoPlaySilentVideo from "../AutoPlaySilentVideo";

export default function Index() {
    useEffect(() =>{
        document.title = "Reliance POS";
    }, []);
  return (
    <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
      <div className="col-12">
        {/* <video src={videoBg} id="background-video" autoPlay loop muted poster="" className="col-12"/> */}
        <AutoPlaySilentVideo video={videoBg} className="col-12 background-video"/>
        <div id="video-text" className="col-xl-5 col-lg-7 col-md-9 ms-xl-5 ms-lg-3 pt-xl-4 pb-xl-4 pt-lg-3 pb-lg-3 ps-md-5 ps-xl-0 d-none d-lg-block">
            <h1 className="white-text pt-xl-5 pt-lg-5"><strong>Point of Sale Systems</strong></h1>
            <h2 id="homepage-colored-header pt-md-0"><strong><em className="colored-text h1">Truly Reliable</em></strong></h2>
            <p className="pt-lg-3 pb-xl-5 pb-lg-4 pb-md-3 white-text lead">ReliancePOS delivers fast and affordable, easy-to-use payment processing, that allows merchants to effectively manage their business to fullest potential.</p>
            <button type="button" className="btn main-btn ps-4 pe-4 mb-lg-3"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
        </div>
      </div>
      <br></br>
      <div className="container mx-auto pt-4">
            <h3 className="mx-auto col-8 text-center pb-2">Boost Your Business with <strong className="colored-text">Reliance POS</strong></h3>
            <h5 className="row mx-auto col-md-10 col-xl-8 text-center lead">Power your business today with a POS system that brings premium performance at a value price. As a total all-in-one solution optimized for liquor stores, convenience store and restaurants, Reliance POS delivers all the essentials you need.</h5>
      </div>
      <br></br>
      <div className="container">
        {/* logos */}
        <div className="row logos-row col-md-12 mb-xl-5 mt-xl-5 mb-md-3 mt-md-4 mb-3 mt-3 mx-auto">
            <div className="col-md-3 col-6 mx-auto">
                <img src="img/reliance-liquor-logo.png" alt="reliance-liquor" className="logos mx-auto"/>
            </div>
            <div className="col-md-3 col-6 mx-auto">
                <img src="img/korona-pos-logo.png" alt="Korona-pos" className="logos mx-auto"/>
            </div>
            <div className="col-md-3 col-6 pt-md-0 pt-3 mx-auto">
                <img src="img/reliance-retail-logo.png" alt="reliance-retail" className="logos mx-auto"/>
            </div>
            <div className="col-md-3 col-6 pt-md-0 pt-3 mx-auto">
                {/* <img src="img/tonic-logo.png" alt="tonic" className="logos mx-auto"/> */}
                <img src="img/Valor-PayTech-Logo-Transparent.png" alt="valor-paytech" className="logos mx-auto"/>
            </div>
        </div>
        <div className="row col-12 pb-5 mx-auto ps-md-5 pe-md-5 ps-xl-0 pe-xl-0">
            <div className="col-xl-3 col-md-6">
                <div className="homepage-box mt-3">
                    <img src="img/liquor-bottles.webp" alt="reliance liquor" className="homepage-box-pic col-12"/>
                    <div className="p-3 homepage-box-text">
                        <h3>Reliance Liquor</h3>
                        <p className="ps-2 pe-2">Reliance Liquor POS is the ultimate destination for all your liquor store needs. </p>
                        <button type="button" className="btn secondary-button"><a href="/POS-RelianceLiquor" className="text-decoration-none colored-text hover-white-text">Learn more</a></button>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="homepage-box mt-3">
                    <img src="img/cutting-edge-homepage-box.png" alt="valor paytech" className="homepage-box-pic col-12"/>
                    <div className="p-3 homepage-box-text col-12">
                        <h3>Valor PayTech</h3>
                        <p className="ps-2 pe-2">Experience the future of payment technology.</p>
                        <button type="button" className="btn secondary-button"><a href="/Payment-ValorPaytech" className="text-decoration-none colored-text hover-white-text">Learn more</a></button>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6 pt-md-3 pt-xl-0">
                <div className="homepage-box mt-3">
                    <img src="img/zero-fee-pos.webp" alt="image2" className="homepage-box-pic col-12"/>
                    <div className="p-3 homepage-box-text">
                        <h3>True Cash Discount POS</h3>
                        <p className="ps-2 pe-2">Dual Pricing Cash Discount program is a unique pricing strategy to give customers the choice to pay with cash instead of a credit card, thereby avoiding credit card processing fees. </p>
                        <button type="button" className="btn secondary-button"><a href="/POS-DPTCDP" className="text-decoration-none colored-text hover-white-text">Learn more</a></button>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6 pt-md-3 pt-xl-0">
                <div className="homepage-box mt-3">
                    <img src="img/image4.png" alt="image4" className="homepage-box-pic col-12"/>
                    <div className="p-3 homepage-box-text">
                        <h3>Gas Station</h3>
                        <p className="ps-2 pe-2">We partner with the best-in-class processor for all things pump processing.</p>
                        <button type="button" className="btn secondary-button"><a href="/POS-GasStation" className="text-decoration-none colored-text hover-white-text">Learn more</a></button>
                    </div>
                </div>
            </div>
        </div> {/* End of boxes-row */}
      </div>
      
      
    </div> // End of container
  );
}