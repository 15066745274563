import { useEffect } from "react";

export default function PaymentPAXTechnology () {
    useEffect(() =>{
        document.title = "PAX Technology Payment";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div>
                
                <div className="row col-12 center-header colored-bg header">
                    <div className="header-vertical-align col-xl-7 col-lg-6 col-md-12">
                        <span className="">
                            <div className="ps-md-5 ps-4 mt-4 mx-auto">
                                <h1 className="white-text pb-2"><strong>PAX Technology</strong></h1>
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                            </div>
                        </span>
                    </div>
                    <div className="col-lg-6 col-xl-5 pe-0">
                        <img src="img/pax-header.webp" alt="valor paytech logo" className="col-12 d-none d-lg-block ps-0 pe-0"/>
                    </div>
                </div>
                <div className="pt-5 pb-5 ps-4 pe-4">
                    <h3 className="ps-md-4 dark-blue-text pb-2">Countertop</h3>
                    <div className="container mx-auto pt-3">
                        <div className="row">
                            <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                <img src="img/A80.webp" alt="countertop-a80" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                            </div>
                            <div className="col-12 col-md-6 ps-0 ps-md-3 pt-3 pt-md-0">
                                <h4 className="colored-text">A80</h4>
                                <div className="lead pb-2">SMART DESKTOP</div>
                                <div>
                                    PAX A80, the most cost-effective in the A-series, is a powerful, game-changing countertop 
                                    device that can also be used as an indoor portable device. Always running at peak performance, 
                                    full connectivity means the A80 is a reliable workhorse that will process payments safely and 
                                    fast, even during the busiest hours.
                                </div>
                                <ul className="pt-3 pb-2">
                                    <li>PAXBiz® powered by Android™</li>
                                    <li>4″ HD Touchscreen Display</li>
                                    <li>3.5 in/sec high-speed printer</li>
                                    <li>PCI PTS 6.x</li>
                                </ul>
                                <button type="button" className="btn main-btn"><a href='pdf/A80-Datasheet.pdf' target='_blank' rel='noopener noreferrer' className="text-decoration-none white-text">Download Data Sheet</a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div className="light-gray-bg pt-5 pb-5 ps-4 pe-4">
                    <h3 className="ps-md-4 dark-blue-text pb-2">Pinpads</h3>
                    <div className="container mx-auto pt-3 pb-5">
                        <div className="row">
                            <div className="col-10 col-md-6 pe-md-3 ps-lg-5 ps-0 pe-0 mx-auto">
                                <img src="img/A35_with_screen.webp" alt="countertop" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                            </div>
                            <div className="col-12 col-md-6 ps-md-3 ps-0 pt-3 pt-md-0">
                                <h4 className="colored-text">A35</h4>
                                <div className="lead pb-2">SMART DESKTOP</div>
                                <div>
                                The A35 is a high-performance, Smart Desktop device, ergonomically designed to address 
                                multiple different retail use cases. Based upon PAXBiz powered by Android 10, the A35 is 
                                designed to support in-device semi-integration payment applications, or it can be used as 
                                a payment input peripheral interfacing to another device that manages the payment application. 
                                Easily integrated with any POS system, it is the perfect solution for personalizing the payment 
                                experience and offering value-added services at the point of sale, such as loyalty programs, advertising, 
                                and data entry (surveys). The A35 adheres to the latest PCI PTS 6.x and SRED payment industry 
                                security standards, features a vivid touchscreen display, and has an innovative cable design that 
                                provides high-speed Ethernet, as well as Power-Over-Ethernet or USB power. In addition to accepting standard 
                                card-based payment, the A35 is equipped to accept touch-less payments such as contactless (NFC) and QR codes.
                                </div>
                                <ul className="pt-3 pb-2">
                                    <li>Features our latest operating system, PAXBiz® powered by Android™</li>
                                    <li>Adheres to the latest PCI PTS 6.x and SRED security standards</li>
                                    <li>Accepts contactless, EMV, and magnetic stripe payments</li>
                                    <li>Features a vivid, four-inch capacitive touchscreen</li>
                                    <li>Innovative cable design that incorporates high-speed Ethernet, RS232, and USB, as well as optional Power-Over-Ethernet</li>
                                </ul>
                                <button type="button" className="btn main-btn"><a href='pdf/A35-Datasheet.pdf' target='_blank' rel='noopener noreferrer' className="text-decoration-none white-text">Download Data Sheet</a></button>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <hr width="50%" className="mx-auto"></hr>
                    <br></br>
                    <div className="container mx-auto pt-5">
                        <div className="row">
                            <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                <img src="img/q25_with_screen.webp" alt="countertop" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                            </div>
                            <div className="col-12 col-md-6 ps-0 ps-md-3 pt-3 pt-md-0">
                                <h4 className="colored-text">Q25</h4>
                                <div className="lead pb-2">RETAIL PIN PAD</div>
                                <div>
                                    The Q25 is a PCI PTS 5.x-certified consumer-facing and activated PIN 
                                    Pad that supports all forms of electronic payment, including magnetic 
                                    stripe cards, EMV smart cards, NFC contactless cards & phones, and QR 
                                    codes. The sleek and ergonomic design of the Q25 lends itself to being 
                                    mounted on a stand/countertop or handed over to the consumer. It is 
                                    equipped with a color LCD display, an integrated PIN-entry keypad, a 
                                    hardened glass display, and an optional privacy shield. The Q25 is 
                                    designed to be connected to and driven by a merchant-facing PAX 
                                    payment terminal, such as the A80, or a Point-of-Sale (POS) terminal. 
                                    These connected devices manage the payment transactions, 
                                    communicating with the appropriate financial institution or processing 
                                    gateway in full integration mode.
                                </div>
                                <ul className="pt-3 pb-2">
                                    <li>PCI PTS 5.x, SRED</li>
                                    <li>0.3 MP Fixed Focus Camera: Supports 1D/2D Barcode and QR Code Scanning</li>
                                    <li>2.8” (320 x 240) LCD Touchscreen</li>
                                    <li>Supports DES, 3DES, RSA, AES</li>
                                    <li>Secure PIN Entry with Optional Privacy Shield	</li>
                                </ul>
                                {/* <button type="button" className="btn main-btn">Download Data Sheet</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div className="pb-5 ps-4 pe-4">
                    <h3 className="ps-md-4 dark-blue-text pb-2">Mobile</h3>
                    <div className="container col-12 mx-auto pt-3">
                        <div className="row">
                            <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                                <img src="img/A920.jpg" alt="countertop" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width:"auto"}}/>
                            </div>
                            <div className="col-12 col-md-6 ps-0 pt-3">
                                <h4 className="colored-text">A920</h4>
                                <div className="lead pb-2">SMART MOBILE</div>
                                <div>
                                    The A920 is the revolutionary terminal that started it all. A strikingly 
                                    beautiful and ergonomically designed terminal, this SmartMobile delivers 
                                    elegance, style, and security to every payment experience. The A920 
                                    combines the full features of an Android tablet with a powerful payment 
                                    terminal, all in a sleek and compact design. The A920 delivers an 
                                    integrated camera, high-speed thermal printer, and high-capacity battery 
                                    to meet the daily demands across all dynamic Retail or Hospitality 
                                    environments.
                                </div>
                                <ul className="pt-3 pb-2">
                                    <li>PAXBiz® powered by Android™</li>
                                    <li>5″ IPS Touchscreen</li>
                                    <li>2″ Thermal Printer, 80mm/sec</li>
                                    <li>PCI PTS 5.x</li>
                                </ul>
                                <button type="button" className="btn main-btn"><a href='pdf/A920-Datasheet.pdf' target='_blank' rel='noopener noreferrer' className="text-decoration-none white-text">Download Data Sheet</a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>

            </div>

        </div>
    )
}