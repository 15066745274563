import { useEffect } from "react";

export default function POSClover() {
    useEffect(() =>{
        document.title = "Clover | POS Solutions";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            {/* <br></br>
            <h3 className="colored-text ps-4 ms-3 pb-2">RESTAURANT</h3>
            <div className="row col-12 ps-5 ms-4 pb-0">
                <p className="col-2"><a href="/POS-Tonic" className="text-decoration-none text-dark"><u>Tonic</u></a></p>
                <p className="col-2"><a href="#clover" className="text-decoration-none colored-text"><strong>Clover</strong></a></p>
            </div> */}
            <div className="">
                <div className="row col-12 mx-auto center-header header colored-bg">
                    <img src="img/restaurant-clover-main.webp" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0"/>
                    <div className="header-vertical-align col-lg-7 col-md-12">
                        <span className="">
                            <div className="ps-md-5 ps-4 mt-4 mx-auto">
                                <h1 className="white-text pb-2"><strong>Clover</strong></h1>
                                {/* <p className="white-text ms-4 col-12 lead">
                                    Two great restaurant POS systems                                   </p> */}
                                <div className="">
                                    <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                {/* <div className="row col-12 mx-auto center-header pt-5 pb-5 part-colored-bg">
                    <div className="col-lg-7 col-md-12 pb-5">
                        <span className="">
                            <div className="ps-md-5 ps-4  mx-auto">
                                <h1 className="white-text"><strong>Clover</strong></h1>
                                <div className="">
                                </div>
                            </div>
                        </span>
                    </div>
                    <img src="img/station-solo.jpg" alt="solo station" className="col-10 mx-auto m-0 p-0 floating-image-no-shadow"/>
                </div> */}
                <div className="container col-10">
                    <br></br>
                    <br></br>
                    <div className="mx-auto col-12 row">
                        <div className="col-12 col-lg-5 p-5 light-gray-bg">
                            <div className="col-12 pb-3">
                                <img src="img/station-duo.webp" alt="" className="col-12"/>
                            </div>
                            <div className="col-12 ps-3 pe-3">
                                <h4 className='display-6 colored-text'>Station Duo</h4>
                                <div className="lead ps-3">
                                    Perfect for restaurants that need to move fast. Station Duo is our most 
                                    powerful POS system. From managing your menu and orders, to overseeing your staff and running reports,
                                    it's all at your fingertips. Comes with two screens—one for you, one for your guests.
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-12 col-lg-5 p-5 light-gray-bg mt-5 mt-lg-0">
                            <div className="col-12 pb-3">
                                <img src="img/mini.jpg" alt="" className="col-12"/>
                            </div>
                            <div className="col-12 ps-3 pe-3">
                                <h4 className="display-6 colored-text">Mini</h4>
                                <div className="lead ps-3">
                                    Full-featured and compact, the Mini is ideal for smaller restaurants, especially for pop-up counters and delivery.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row col-12 pb-5 mt-5">
                        <div className="mx-auto col-12 col-lg-6 p-5 light-gray-bg">
                            <div className="col-12 pb-3">
                                <img src="img/flex.webp" alt="" className="col-12"/>
                            </div>
                            <div className="col-12 ps-3 pe-3">
                                <h4 className="display-6 colored-text">Flex</h4>
                                <div className="lead ps-3">
                                    Already have a POS system? Want the flexibility of our powerful mobile POS for curbside, pop-up 
                                    counter or deliveries? Get Clover Flex.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}