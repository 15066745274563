import { useEffect } from "react";

export default function HardwareRelianceI5StationwithBP() {
    useEffect(() =>{
        document.title = "Reliance i5 Station with Built-in Printer | Hardware";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header header colored-bg">
                {/* <img src="img/i5-bp-front-white.jpg" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0 col-xxl-3"/> */}
                {/* <img src="img/i5-bp-side-white.jpg" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0 col-xxl-3"/> */}
                <div id="carouselExampleIndicators" class="carousel carousel-dark slide col-lg-5 d-none d-lg-block ps-0 pe-0 col-xxl-4" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="img/bp-1.webp" class="d-block w-100" alt="..."/>
                        </div>
                        <div class="carousel-item">
                            <img src="img/bp-2.webp" class="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                <div className="header-vertical-align col-lg-7 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>Reliance i5 Station with Built-in Printer </strong></h1>
                            <p className="white-text ms-4 col-10">
                                A new type of POS machine with a super compact all-in-one design and powerful
                                performance combined.
                            </p>
                            <div className="">
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div className="container">
                {/* <div className="col-10 col-md-6 mx-auto">
                    <img src="img/hardware2.png" alt="" className="col-12"/>
                </div> */}
                <div className="col-lg-9 ps-3 pt-3 pt-md-5 pt-md-0 mx-auto">
                    <h4 className="ps-md-4 pb-2 colored-text">General Hardware Description (easy to handle, space saver)</h4>
                    <div className="container col-11 mx-auto pt-3">
                        <ul>
                            <li>Compact & All in One System (i5 processor)</li>
                            <li>Touch Screen Monitor</li>
                            <li>Customer Display</li>
                            <li>Built-in Thermal Receipt Printer</li>
                            <li>Top Quality Barcode Scanner</li>
                            <li>Heavy Duty Cash Drawer</li>
                            <li>Integrated Payment Device</li>
                        </ul>
                    </div>
                </div>
                <br></br>
                <table className="col-10 mx-auto">
                    <tbody>
                        <tr>
                            <th className="p-2">CPU</th>
                            <td className="p-2">Intel Core i5</td>
                            <th className="p-2">RAM</th>
                            <td className="p-2">DDR3, 4GB, 8GB</td>
                        </tr>
                        <tr>
                            <th className="p-2">Storage</th>
                            <td className="p-2">Sandisk SSD 64G</td>
                            <th className="p-2">Operating System</th>
                            <td className="p-2">Window 10 Pro</td>
                        </tr>
                        <tr>
                            <th className="p-2">Display panel</th>
                            <td className="p-2">15" & 17" LED Back light, 1024x768 LVDS</td>
                            <th className="p-2">Touch Screen</th>
                            <td className="p-2">Capacitive touch screen (resistive touch optional)</td>
                        </tr>
                        <tr>
                            <th className="p-2">Customer Display</th>
                            <td className="p-2">LCD 12.1"</td>
                            <th className="p-2">Printer Port</th>
                            <td className="p-2">USB and RS232</td>
                        </tr>
                        <tr>
                            <th className="p-2">Power Supply</th>
                            <td className="p-2">DC12V, 5A</td>
                            <th className="p-2">Printer</th>
                            <td className="p-2">80 mm</td>
                        </tr>
                    </tbody>
                </table>

                <br></br>
            </div>
        </div>
    )
}