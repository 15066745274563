// import { Link } from "react-router-dom";

export default function Footer() {
    return(
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0">
            <footer className="footer pt-4 ps-5">
                <div>
                    {/* <button type="button" className="btn main-btn float-right me-5 mt-5">Contact Us</button> */}
                    <div className="row pb-4 me-0">
                        <h5><strong>Reliance Card Services</strong></h5>
                        <div>
                            1675 Lakes Pkwy Ste 105
                        </div>
                        <div>Lawrenceville, GA 30043</div>
                        <div>(770) 344-7300</div>
                    </div>
                    <div className="float-right me-2 me-md-5">
                        {/* <strong className="me-3"><Link className="text-decoration-none text-dark">Terms & Conditions</Link></strong>
                        <strong>|</strong> */}
                        <strong className="ms-3"><a href="/PrivacyPolicy" className="text-decoration-none text-dark">Privacy Policy</a></strong>
                    </div>
                    <button type="button" className="btn main-btn ps-3 pe-3 mb-4"><a href="/ContactUs" className="text-decoration-none text-white">Contact Us</a></button>
                </div>
            </footer>
        </div>
    )
}