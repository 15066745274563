import { useEffect } from "react";

export default function HardwareRelianceI5Station() {
    useEffect(() =>{
        document.title = "Reliance i5 Station | Hardware";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header header colored-bg">
                {/* <img src="img/i5-station-front-white.jpg" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0 col-xxl-3"/> */}
                {/* <img src="img/i5-station-side-white.jpg" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0 col-xxl-4"/> */}
                <div id="carouselExampleIndicators" className="carousel carousel-dark slide col-lg-5 d-none d-lg-block ps-0 pe-0 col-xxl-4" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="img/station-1.webp" className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src="img/station-2.webp" className="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <div className="header-vertical-align col-lg-7 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>Reliance i5 Station</strong></h1>
                            <p className="white-text ms-4 col-10">
                                Top-of-the-line All in one system POS terminal proves unmatched
                                speed and power by intel® core™ i5 processor. It also boasts a high-
                                quality touch screen, various i/o ports, and easy setup.
                            </p>
                            <div className="">
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div className="container">
                <div className="container mx-auto pt-3 col-12 col-xl-10 col-xxl-9">
                    <div className="row">
                        <div className="col-12 col-md-6 ps-3 pt-3 pt-lg-5 pt-md-0">
                            <h4 className="ps-md-4 pb-2 colored-text">General Hardware Description</h4>
                            <div className="container col-11 mx-auto pt-3">
                                <ul>
                                    <li>Compact & All in One System (i5 processor)</li>
                                    <li>Touch Screen Monitor</li>
                                    <li>Customer Display / Pole Display</li>
                                    <li>Top Quality Thermal Receipt Printer</li>
                                    <li>Top Quality Barcode Scanner</li>
                                    <li>Heavy Duty Cash Drawer</li>
                                    <li>Integrated Payment Device</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-10 col-md-6 ps-0 pe-0 pe-md-3 ps-lg-5 mx-auto">
                            <img src="img/hardware-set-compact.webp" alt="" className="col-11 mx-auto img-fluid" style={{maxHeight: "400px", width: "auto"}}/>
                        </div>
                    </div>
                </div>
                <br></br>
                <table className="col-10 mx-auto">
                    <tbody>
                        <tr>
                            <th className="p-2">CPU</th>
                            <td className="p-2">Intel Core i5</td>
                            <th className="p-2">RAM</th>
                            <td className="p-2">DDR3, 4GB, 8GB</td>
                        </tr>
                        <tr>
                            <th className="p-2">Storage</th>
                            <td className="p-2">Sandisk SSD 64G</td>
                            <th className="p-2">Operating System</th>
                            <td className="p-2">Window 10 Pro</td>
                        </tr>
                        <tr>
                            <th className="p-2">Display panel</th>
                            <td className="p-2">15" & 17" LED Back light, 1024x768 LVDS</td>
                            <th className="p-2">Touch Screen</th>
                            <td className="p-2">Capacitive touch screen (resistive touch optional)</td>
                        </tr>
                        <tr>
                            <th className="p-2">Customer Display</th>
                            <td className="p-2">LCD 12.1"</td>
                            <th className="p-2">Printer Port</th>
                            <td className="p-2">USB and RS232</td>
                        </tr>
                        <tr>
                            <th className="p-2">Power Supply</th>
                            <td className="p-2">DC12V, 5A</td>
                            <th className="p-2">Color of housing</th>
                            <td className="p-2">Red/black, white/black</td>
                        </tr>
                    </tbody>
                </table>

                <br></br>
                {/* <table>
                    <tr>
                        <td className="col-12 col-md-6">
                            <tr>
                                <th>CPU</th>
                                <td>Intel Core I5</td>
                            </tr>
                            <tr>
                                <th>Storage</th>
                                <td>Sandisk SSD 64G</td>
                            </tr>
                            <tr>
                                <th>Display panel</th>
                                <td>15" & 17" LED Back light, 1024x768 LVDS</td>
                            </tr>
                            <tr>
                                <th>Customer Display</th>
                                <td>LCD 12.1"</td>
                            </tr>
                            <tr>
                                <th>Power Supply</th>
                                <td>DC12V, 5A</td>
                            </tr>
                        </td>
                        <td className="col-12 col-md-6">
                            <tr>
                                <th>RAM</th>
                                <td>DDR3, 4GB, 8GB</td>
                            </tr>
                            <tr>
                                <th>Operating System</th>
                                <td>Window 10 Pro</td>
                            </tr>
                            <tr>
                                <th>Touch Screen</th>
                                <td>Capacitive touch screen (resistive touch optional)</td>
                            </tr>
                            <tr>
                                <th>Printer Port</th>
                                <td>USB and RS232</td>
                            </tr>
                            <tr>
                                <th>Color of housing</th>
                                <td>Red/black, white/black</td>
                            </tr>
                        </td>
                    </tr>
                </table> */}
            </div>
        </div>
    )
}