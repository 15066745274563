import { useEffect } from "react";

export default function PaymentValorPaytech () {
    useEffect(() =>{
        document.title = "Valor PayTech | Payment Solutions";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
                <div className="row col-12 center-header colored-bg header">
                    <div className="header-vertical-align col-xxl-9 col-xl-8 col-lg-7 col-md-12">
                        <span className="">
                            <div className="ps-md-5 ps-4 mt-4 mx-auto">
                                <h1 className="white-text pb-2"><strong>Valor PayTech (Cloud Based)</strong></h1>
                                <p className="white-text ms-4 col-10">
                                    Valor PayTech is a fast-growing fintech provider of end-to-end, processor-agnostic omnichannel payments solutions. The company equips both merchants and reseller partners with comprehensive, cutting-edge tools and flexibility to succeed in today’s rapidly changing business environment. Experience the future of payments.
                                </p>
                                <div className="">
                                    <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="col-lg-5 col-xl-4 col-xxl-3 pe-0">
                        {/* <img src="img/valor-paytech-screenshot.png" alt="reliance-liquor" className="col-12 d-none d-lg-block ps-0 pe-0"/> */}
                        <img src="img/cutting-edge.webp" alt="" className="col-12 d-none d-lg-block ps-0 pe-0"/>
                    </div>
                </div>
                <br></br>
                <div className="container mx-auto pt-4 pb-4">
                    <h2 className="display-6 mx-auto col-md-12 col-lg-10 text-center dark-blue-text pt-5 pb-3 fw-bold">EXPERIENCE THE FUTURE OF PAYMENT TECHNOLOGY</h2>
                    {/* <h5 className="row mx-auto col-10 text-center lead">AM/PM Service represents many of the leading POS suppliers to find the right software solution for your small, medium, or large retail business.</h5> */}
                    {/* <div className="col-md-12 col-lg-9 mx-auto">
                        <img src="img/valor-devices.png" alt="Valor Paytech Products" className="col-12"/>
                    </div> */}
                </div>
                <div className="">
                    <br></br>
                    <br></br>
                    <h4 className="ps-4 dark-blue-text">Reliance's New Card Machine</h4>
                    <div className="container col-11 mx-auto">
                        <br></br>
                        <div className="row mb-2">
                            <div className="col-lg-5 pt-lg-5">
                                <img src="img/cloud-network.webp" alt="" className="col-12 pt-xl-3 pt-lg-5"/>
                            </div>
                            <div className="col-lg-7 ps-5 pt-4 pt-lg-0">
                                <div className="">
                                    <div className="med-text mb-1 dark-blue-text float-left"><strong className="colored-text">✓</strong></div>
                                    <p className="ps-4 lead">Revolutionized Cloud-Based Solution</p>
                                </div>
                                <div className="">
                                    <div className="med-text mb-1 dark-blue-text float-left"><strong className="colored-text">✓</strong></div>
                                    <p className="ps-4 lead">Faster Transaction, Clearer Receipt Prints than All Other Terminals</p>
                                </div>
                                <div className="">
                                    <div className="med-text mb-1 dark-blue-text float-left"><strong className="colored-text">✓</strong></div>
                                    <p className="ps-4 lead">Large Display and e-Signature Capture</p>
                                </div>
                                <div className="">
                                    <div className="med-text mb-1 dark-blue-text float-left"><strong className="colored-text">✓</strong></div>
                                    <p className="ps-4 lead">Customer Receipt and Signature Storage (24 months) for Chargeback Protection</p>
                                </div>
                                <div className="">
                                    <div className="med-text mb-1 dark-blue-text float-left"><strong className="colored-text">✓</strong></div>
                                    <p className="ps-4 lead">Dashboard in the Cloud; Access from Anywhere in the World and Check Sales Data in Realtime, Perform Refund/Void, Adjust Tips, etc</p>
                                </div>
                                <div className="">
                                    <div className="med-text mb-1 dark-blue-text float-left"><strong className="colored-text">✓</strong></div>
                                    <p className="ps-4 lead">Build Customer Database Automatically</p>
                                </div>
                                <div className="">
                                    <div className="med-text mb-1 dark-blue-text float-left"><strong className="colored-text">✓</strong></div>
                                    <p className="ps-4 lead">Real-Time Risk Monitoring with Valor Shield</p>
                                </div>
                                <div className="">
                                    <div className="med-text mb-1 dark-blue-text float-left"><strong className="colored-text">✓</strong></div>
                                    <p className="ps-4 lead">Built-In Marketing Tool to Engage Customers</p>
                                </div>
                                <div className="">
                                    <div className="med-text mb-1 dark-blue-text float-left"><strong className="colored-text">✓</strong></div>
                                    <p className="ps-4 lead">Digital and Paper Receipt Advertisement</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                </div>
                <div className="light-gray-bg">
                    <h4 className="ps-4 dark-blue-text pt-5 pb-2">Features</h4>
                    <br></br>
                    <div className="row ps-4 pe-5">
                        {/* <div className="col-3">
                            <img src="img/image3.png" alt="features" className="col-12 rounded-borders d-none d-lg-block"/>
                        </div> */}
                        {/* <div className="col-lg-8 col-md-12 ps-5"> */}
                            <div className="col-12 row mx-auto pb-lg-3 pb-md-4">
                                <div className="col-md-4 pb-2 pb-md-0">
                                    <img src="img/icon/wifi-icon.png" alt="Inventory Management Icon" className="icon float-left pb-3"/>
                                    <p className="ps-5">Ethernet & Wi-Fi Connectivity</p>
                                </div>
                                <div className="col-md-4 pb-2 pb-md-0">
                                    <img src="img/icon/cash-discounting-icon.png" alt="Case Break and Mix & Match Icon" className="icon float-left"/>
                                    <p className="ps-5">Customizable Surcharge & Cash Discounting Program</p>
                                </div>
                                <div className="col-md-4 pb-3 pb-md-0">
                                    <img src="img/icon/connection-icon.png" alt="Case Break and Mix & Match Icon" className="icon float-left"/>
                                    <p className="ps-5">Auto-Connection Fallback Procedure</p>
                                </div>
                            </div>
                            <br></br>
                            <div className="col-12 row mx-auto pb-lg-3 pb-md-4">
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/touch-screen-icon.png" alt="Excellent Sales Recording & Reporting Icon" className="icon float-left"/>
                                    <p className="ps-5">Large Scale 3.5" Touch Screen Display</p>
                                </div>
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/receipt-icon.png" alt="Promotions icon" className="icon float-left"/>
                                    <p className="ps-5">Text & Store Digital Receipts</p>
                                </div>
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/mobile-icon.png" alt="Case Break and Mix & Match Icon" className="icon float-left"/>
                                    <p className="ps-5">Mobile Number Capturing</p>
                                </div>
                            </div>
                            <br></br>
                            <div className="col-12 row mx-auto pb-lg-3 pb-md-4">
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/exchange-icon.png" alt="Customer Loyalty Integration Icon" className="icon float-left"/>
                                    <p className="ps-5">Dual MID Feature</p>
                                </div>
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/contactless-icon.png" alt="Online Store and eCommerce Integration Icon" className="icon float-left"/>
                                    <p className="ps-5">Contactless Payment</p>
                                </div>
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/signature-icon.png" alt="Case Break and Mix & Match Icon" className="icon float-left"/>
                                    <p className="ps-5">Supports On Screen Signature</p>
                                </div>
                            </div>
                            <br></br>
                            <div className="col-12 row mx-auto pb-lg-3 pb-md-4">
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/signal-icon.png" alt="Employee Management & Permissions Icon" className="icon float-left"/>
                                    <p className="ps-5">Remote Diagnostics</p>
                                </div>
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/customer-rating.png" alt="Case Break and Mix & Match Icon" className="icon float-left"/>
                                    <p className="ps-5">Built-In Customer Feedback Rating System</p>
                                </div>
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/instant-icon.png" alt="Case Break and Mix & Match Icon" className="icon float-left"/>
                                    <p className="ps-5">Instant Deployment</p>
                                </div>
                            </div>
                            <div className="col-12 row mx-auto pb-lg-3 pb-md-4">
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/bill-icon.png" alt="Employee Management & Permissions Icon" className="icon float-left"/>
                                    <p className="ps-5">Bill Splitting</p>
                                </div>
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/mobile-text-icon.png" alt="Case Break and Mix & Match Icon" className="icon float-left"/>
                                    <p className="ps-5">SMS Text/Email Marketing with Engage My Customer</p>
                                </div>
                                <div className="col-md-4 pb-3">
                                    <img src="img/icon/key-icon.png" alt="Case Break and Mix & Match Icon" className="icon float-left"/>
                                    <p className="ps-5">Supports Remote Key Injection</p>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                    <br></br>
                    <br></br>
                    <div className="col-md-12 col-lg-9 mx-auto">
                        <img src="img/valor-devices.webp" alt="Valor Paytech Products" className="col-12"/>
                    </div>
                </div>

        </div>
    )
}