
export default function DropdownMenu(props) {
    const menus = [
        {
            title: "POS Solutions",
            count: 4,
            dropdown: [
                {
                    name: "Liquor Store",
                    link: "/POS-RelianceLiquor"
                },
                {
                    name: "Restaurant",
                    link: "/POS-Clover"
                },
                {
                    name: "Retail Store",
                    link: "/POS-Retail"
                },
                {
                    name: "Gas Station",
                    link: "/POS-GasStation"
                }
                // ,
                // {
                //     name: "True Cash Discount POS",
                //     link: "/POS-DPTCDP"
                // }
            ]
        },
        {
            title: "Cash Discount POS",
            count: 2,
            dropdown: [
                {
                    name: "POS",
                    link: "/POS-DPTCDP"
                },
                {
                    name: "Terminal",
                    link: "/Payment-DPCD"
                }
            ]
        },
        {
            title: "Hardware",
            count: 5,
            dropdown: [
                {
                    name: "Reliance i5 Station",
                    link: "/Hardware-RelianceI5Station"
                },
                {
                    name: "Reliance i5 Station with Built-in Printer",
                    link: "/Hardware-RelianceI5Station-withBP"
                },
                {
                    name: "Reliance i5 Convenience Store",
                    link: "/Hardware-RelianceI5ConvenienceStore"
                },
                {
                    name: "Clover Station",
                    link: "/Hardware-CloverStation"
                }
                // ,
                // {
                //     name: "Peripherals",
                //     link: "/Hardware-Peripherals"
                // }
            ]
        },
        {
            title: "Payment Solutions",
            count: 4,
            dropdown: [
                {
                    name: "Valor PayTech",
                    link: "/Payment-ValorPaytech"
                },
                {
                    name: "PAX Technology",
                    link: "/Payment-PAXTechnology"
                },
                {
                    name: "FD Terminals",
                    link: "/Payment-FDTerminals"
                },
                {
                    name: "Dejavoo Terminals",
                    link: "/Payment-DejavooTerminals"
                }
                // ,
                // {
                //     name: "Dual Pricing Cash Discount",
                //     link: "/Payment-DPCD"
                // }
            ]
        },
        {
            title: "Contact Us",
            count: 2,
            dropdown: [
                {
                    name: "About Us",
                    link: "/AboutUs"
                },
                {
                    name: "Contact Us",
                    link: "/ContactUs"
                }
            ]
        }
    ]
    return (
        <ul className="dropdown-menu ps-1 pe-1" style={{display: 'block'}} aria-labelledby="posDropdown">
            {menus[props.index].dropdown.map((item) => (
                <li key={item.name}>
                    <a href={item.link} className="nav-link dropdown-item">{item.name}</a>
                </li>
            ))}
        </ul>
    )
}