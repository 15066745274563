import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Index from './routes/Index';
import Root from './routes/Root';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './styles.css';
import POSRelianceLiquor from './routes/POSRelianceLiquor';
// import POSTonic from './routes/POSTonic';
import DualPricingTCDP from './routes/POS-DPTCDP';
import POSKorona from './routes/POSKorona';
import PaymentValorPaytech from './routes/PaymentValorPaytech';
import POSGasStation from './routes/POSGasStation';
import PaymentTCDP from './routes/Payment-TCDP';
import HardwareRelianceI5Station from './routes/Hardware-RelianceI5Station';
import HardwareRelianceI5StationwithBP from './routes/Hardware-RelianceI5Station-withBP';
import HardwareRelianceI5ConvenienceStore from './routes/Hardware-RelianceI5ConvenienceStore';
import PaymentPAXTechnology from './routes/Payment-PAXTechnology';
import PaymentFDTerminals from './routes/Payment-FDTerminals';
import PaymentDejavooTerminals from './routes/Payment-DejavooTerminals';
import HardwareCloverStation from './routes/Hardware-CloverStation';
import AboutUs from './routes/AboutUs';
import ContactUs from './routes/ContactUs';
import POSClover from './routes/POSClover';
import POSRetail from './routes/POSRetail';
// import HardwarePeripherals from './routes/Hardware-Peripherals';
import PrivacyPolicy from './routes/PrivacyPolicy';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      { path: '/', element: <Index /> },
      { path: '/POS-RelianceLiquor', element: <POSRelianceLiquor/>},
      { path: '/POS-DPTCDP', element: <DualPricingTCDP/>},
      { path: '/POS-Korona', element: <POSKorona/>},
      { path: '/POS-Clover', element: <POSClover/>},
      { path: 'POS-Retail', element: <POSRetail/>},
      { path: '/POS-GasStation', element: <POSGasStation/>},
      { path: '/Hardware-RelianceI5Station', element: <HardwareRelianceI5Station/>},
      { path: '/Hardware-RelianceI5Station-withBP', element: <HardwareRelianceI5StationwithBP/>},
      { path: '/Hardware-RelianceI5ConvenienceStore', element: <HardwareRelianceI5ConvenienceStore/>},
      { path: '/Hardware-CloverStation', element: <HardwareCloverStation/>},
      // { path: '/Hardware-Peripherals', element: <HardwarePeripherals/>},
      { path: '/Payment-ValorPaytech', element: <PaymentValorPaytech/>},
      { path: '/Payment-PAXTechnology', element: <PaymentPAXTechnology/>},
      { path: '/Payment-FDTerminals', element: <PaymentFDTerminals/>},
      { path: '/Payment-DejavooTerminals', element: <PaymentDejavooTerminals/>},
      { path: '/Payment-DPCD', element: <PaymentTCDP/>},
      { path: '/AboutUs', element: <AboutUs/>},
      { path: '/ContactUs', element: <ContactUs/>},
      { path: '/PrivacyPolicy', element: <PrivacyPolicy/>}
    ]
  }
])



root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
