import { useEffect } from "react";

export default function POSGasStation() {
    useEffect(() =>{
        document.title = "Gas Station | POS Solutions";
    }, []);

    return (
        <div className="ms-xl-5 me-xl-5 ms-md-2 me-md-2 ms-sm-0 me-sm-0 bg-light">
            <div className="row col-12 mx-auto center-header header colored-bg">
                <img src="img/bp.jpg" alt="" className="col-lg-5 d-none d-lg-block ps-0 pe-0"/>
                <div className="header-vertical-align col-lg-7 col-md-12">
                    <span className="">
                        <div className="ps-md-5 ps-4 mt-4 mx-auto">
                            <h1 className="white-text pb-2"><strong>Gas Station</strong></h1>
                            <p className="white-text ms-4 col-10">
                                Reliance Card Services has been a partner with one of the leading providers of payment processing solutions, specializing in Independent Gas Stations, Fleet and Convenience stores. 
                            </p>
                            <div className="">
                                <button type="button" className="btn main-btn mt-3 ms-3 mb-5 tertiary-button"><a href="/ContactUs" className="text-decoration-none white-text">Contact Us</a></button>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div className="">
                <div className="row pt-4 pb-lg-5 mt-4 mx-auto pb-md-0">
                    <div className="ps-md-5 ps-3 col-lg-6 pb-lg-3 pb-5 pt-xl-4 pt-xxl-5">
                        <div className="lead col-12 pt-xxl-4">Understanding the individual needs of petroleum and convenience store customers, our payment processing/pay at the pump solutions are tailored to meet each individual customer’s specific needs.</div>
                        <div className="col-11 ps-md-4 ps-4 pt-2">They seamlessly integrate with all of the latest pay at the pump terminals and POS systems and enable immediate acceptance of all major credit cards including debit and fleet cards such as Wright Express, Voyager and the Fuelman Card.</div>
                    </div>
                    <img src="img/gas-station-owner.jpg" alt="gas station owner" className="mx-auto rounded-borders col-lg-5 object-fit d-none d-lg-block"/>
                </div>
                <div className="row pt-4 pb-3 mx-auto pb-lg-3 light-gray-bg">
                    <img src="img/gas-pump.webp" alt="" className="mx-auto rounded-borders col-lg-5 object-fit col-md-8 col-sm-10 "/>
                    <div className="col-lg-6 pb-3">
                        {/* <h5 className="dark-orange-text col-xl-11 pb-3 mx-auto">
                            <strong>ReliancePOS provides powerful True Cash Discount Dual Pricing features that are compliant and
                            convenient pricing solutions.</strong>
                        </h5> */}
                        <div className="pe-5 ps-5 ps-lg-0">
                            <div className="col-xl-11 pb-2 mx-auto pt-3 lead">
                                A recognized industry innovator, PCS played an instrumental role in bringing Datawire Secure Transport to the independent petroleum merchant. Through our affiliation, we offer a cost-effective IP solution for pay at the pump processing through the Buypass network.
                            </div>
                            <div className="col-xl-9 mx-auto pt-3 ps-3">
                                <p className="mb-1 pe-3"><strong>Benefits of Datawire:</strong></p>
                                <ul className="ps-5">
                                    <li>Secure, direct routing of transactions</li>
                                    <li>Expedited transaction processing versus dial-up</li>
                                    <li>Cost savings potential through the eliminating of telecom services</li>
                                    <li>Simplified PCI compliance</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-3 mx-auto pb-3 ps-4 pt-4 pt-lg-2 ">
                    <div className="pe-5">
                        <div className="col-lg-11 col-md-12 pb-2 mx-auto pt-md-3 lead">
                            PCS works with a variety of leading providers of point-of-sale and processing solutions engineered
                            specifically for petroleum and convenience stores including:                            </div>
                        <div className="col-11 mx-auto pt-3 ps-3 row">
                            <ul className="col-lg-3 col-md-6 mb-0">
                                <li>Verifone Commander</li>
                                <li>Verifone Ruby/Topaz/Sapphire</li>
                                <li>Gilbarco Passport</li>
                                <li>Gilbarco Edge</li>
                                <li>Wayne Nucleus</li>
                            </ul>
                            <ul className="col-lg-3 col-md-6 mb-0">
                                <li>ComData Smart Solutions</li>
                                <li>Comadata Smart Desq</li>
                                <li>Fiscal</li>
                                <li>OPW PetroVend FSC3000</li>
                                <li>Triple E</li>
                            </ul>
                            <ul className="col-lg-3 col-md-6 mb-0">
                                <li>The Site Controller/Excentus</li>
                                <li>Rapid RMS? - Yes</li>
                                <li>Fuelmaster</li>
                                <li>J3 POS</li>
                                <li>OPW PV300E</li>
                            </ul>
                            <ul className="col-lg-3 col-md-6">
                                <li>Charge Plus - EV Charging Pedestal</li>
                                <li>Unitec Wash Select II</li>
                                <li>Hamilton</li>
                                <li>Sound Payments</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br></br>
            </div>
        </div>
    )
}